import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted, getCurrentInstance } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    onMounted(() => {
      proxy.$api.post(`${proxy.$url}Map/getKey`).then(res => {
        if (res.data) {
          initMap(res.data ? res.data : 'WI7BZ-XWQKL-ANBPX-EIAFI-JMEW6-V5BPY');
        }
      }).catch(() => {
        initMap('WI7BZ-XWQKL-ANBPX-EIAFI-JMEW6-V5BPY');
      });
    });
    const initMap = key => {
      const script = document.createElement('script');
      script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${key}&libraries=service,geometry`;
      document.head.appendChild(script);
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};